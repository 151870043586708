<template>
  <div class="fr-event-card" :class="{ match: event.triggered.length > 0 }">
    <div class="wrap-photos">
      <div class="time">
        <span>{{ detectTime }}</span> 
      </div>
      <div class="photos">
        <div class="photo" @click="showEventDetail()">
          <img v-lazy="photoObj" alt="">
        </div>
        <div class="photo">
          <img v-lazy="featPhotoObj" alt="">
        </div>
      </div>
      <span v-if="event.triggered.length > 0" class="score">{{ getScore(rankTriggered?.score) }}%</span>
    </div>
    <div class="info">
      <div class="name-index">
        <div v-if="isMatched" class="name">
          <img :src="getGenderIcon(rankTriggered?.gender)" alt="">
          <TooltipElement v-if="rankTriggered" :content="rankTriggered.name">
            <span>{{ rankTriggered?.name }}</span>
          </TooltipElement>
        </div>
        <div v-else class="name">
          <img src="@/assets/icons/user-yellow-L.svg" alt="">
          <span>{{ $t('unknown') }}</span>
        </div>
        <div>
          <TooltipElement :content="String(eventNo)">
          <div class="index">{{ eventNo }}</div>
          </TooltipElement>
        </div>
      </div>
      <div class="content">
        <div class="items">
          <div v-if="isMatched" class="item">
            <TooltipElement :content="$t('history_fr_age'/*年齡*/)" alwaysShow>
              <img src="@/assets/icons/age.svg" alt="">
            </TooltipElement>
            <span>{{ getAge(rankTriggered?.birthday) }}</span>
            <span v-if="rankTriggered?.birthday" class="small">({{ birthday }})</span>
          </div>
          
          <div v-if="isMatched && rankTriggered?.tag.length > 0" class="item tags">
            <TooltipElement :content="$t('history_fr_tag'/*人物標記*/)" alwaysShow>
              <img src="@/assets/icons/tag.svg" alt="">
            </TooltipElement>
            <TooltipElement v-if="rankTriggered" :content="getTagNameStr(rankTriggered.tag, frTagList)" alwaysShow>
              <div class="wrap-tags">
                <span v-for="(tag, index) in getTagNameList(rankTriggered?.tag, frTagList)" :key="index">{{ tag }}</span>
              </div>
            </TooltipElement>
          </div>
          
          <div class="item video-title">
            <TooltipElement :content="$t('history_fr_device'/*設備*/)" alwaysShow>
              <img src="@/assets/icons/Camera.svg" alt="">
            </TooltipElement>
            <TooltipElement :content="getDeviceTitleId(event.user.id)">
              <span>{{ getDeviceTitleId(event.user.id) }}</span>
            </TooltipElement>
          </div>

          <div v-if="event.description" class="item push-content">
            <TooltipElement :content="$t('history_fr_remark'/*備註*/)" alwaysShow>
              <img src="@/assets/icons/note.svg" alt="">
            </TooltipElement >
            <TooltipElement :content="event.description">
              <span>{{ event.description }}</span>
            </TooltipElement>
          </div>
        </div>
        <div class="icons">
          <TooltipElement :content="$t('history_fr_open_google_map'/*開啟Google map*/)" alwaysShow>
            <img src="@/assets/icons/google-map-icon.png" alt="" @click="goGoogleMap(event.latitude, event.longitude)">
          </TooltipElement>
          <EventMisjudgment :event="event" type="fr" />
        </div>
      </div>
    </div>
    <div class="map">
      <img v-if="event.latitude === 0 || event.longitude === 0" src="@/assets/icons/no-gps.svg" alt="">
      <EventMap v-else :event="event" type="fr" :centerShift="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import { getScore, getAge, formatTime, formatTimeNoTime } from '@/utils/lib.js'
import { 
  getGenderIcon, 
  getTagNameStr, 
  getTagNameList,
  goGoogleMap 
} from '@/utils/index.js'

export default {
  name: 'FrEventCard',
  components: {
    EventMap: () => import('@/components/Base/EventMap.vue'),
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    EventMisjudgment: () => import('@/components/Base/EventMisjudgment.vue'),
  },
  props: ['event', 'index'],
  data() {
    return {
      isShowTooltip: false,
    }
  },
  computed: {
    ...mapState(['frTagList']),
    ...mapState('historyFr', ['frCurrentPage', 'frPageSize']),
    ...mapGetters('account', ['getDeviceTitleId']),
    isMatched() {
      return this.event.triggered.length > 0
    },
    eventNo() {
      return (this.frCurrentPage - 1) * this.frPageSize + this.index + 1
    },
    detectTime() {
      return formatTime(this.event.detectTime)
    },
    birthday() {
      return formatTimeNoTime(this.rankTriggered?.birthday)
    },
    rankTriggered() {      
      if (this.event.triggered.length === 0) return null
      if (this.event.triggered.length === 1) return this.event.triggered[0]
      const temp = [...this.event.triggered]
      return temp.sort((a, b) => a.rank - b.rank)[0]
    },
    photoObj() {
      return {
        src: this.event.photoUrl,
        error: require('@/assets/icons/unknow-user.svg'),
      }
    },
    featPhotoObj() {
      return {
        src: this.rankTriggered?.featurePhotoUrl,
        error: require('@/assets/icons/unknow-user.svg'),
      }
    },
  },
  methods: {
    ...mapMutations('history', ['updateHistoryEventModalShow']),
    ...mapMutations('historyFr', ['updateFrCurrentIndex']),
    getScore,
    getAge,
    getGenderIcon,
    getTagNameStr,
    getTagNameList,
    goGoogleMap,
    formatTime,
    showEventDetail() {
      this.updateFrCurrentIndex(this.index)
      this.updateHistoryEventModalShow(true)
    },
  }
}
</script>

<style lang="scss" scoped>
.fr-event-card {
  width: 100%;
  height: 100%;
  background: #4A5C7880;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  &:hover {
    background: #4A5C78cc;
  }
  &::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 100%;
    background: #4A5C78;
    left: 0;
    top: 0;
    border-radius: 8px 0 0 8px;
  }

  &.match::before {
    background: #FFC600;
  }
}

.wrap-photos {
  padding: 12px 12px 12px 24px;
  .time {
    margin-bottom: 12px;
  }
  .time span {
    display: inline-block;
    color: #FFFFFF;
    background: #282942;
    border-radius: 17px;
    padding: 5px 28px;
    font-size: px2rem(18);
    line-height: 24px;
  }

  .photos {
    display: flex;
    column-gap: 8px;
    & .photo:first-child {
      cursor: pointer;
    }
    .photo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 147px;
      height: 147px;
      background: #ffffff1a;
      border-radius: 3px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      img[lazy=error] {
        width: 60%;
        height: 60%;
      }
      
      span {
        display: block;
        color: #FFFFFF80;
      }
    }
  }
  .score {
    font-size: px2rem(20);
    color: #ffffff80;
    margin-left: 155px;
  }
}

.info {
  width: calc(100% - 746px);
  height: 100%;
  padding: 12px 20px 12px 12px;
  box-sizing: border-box;
  .name-index {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    column-gap: 18px;
    .name {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: px2rem(24);
      line-height: 40px;
      font-weight: 500;
      color: #FFE99F;
      
      img {
        margin-right: 8px;
      }
      span {
        font-size: px2rem(24);
        font-weight: 500;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
      }
    }
    .index {
      min-width: 41px;
      max-width: 116px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-size: px2rem(20);
      text-align: center;
      line-height: 28px;
      font-weight: normal;
      color: #282942;
      background: #FFE99F;
      padding: 0 8px;
      border-radius: 17px;
      word-break: break-all;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
  }

  .content {
    height: calc(100% - 45px);
    display: flex;
    column-gap: 20px;
    font-size: px2rem(20);
    line-height: 35px;
    color: #ffffff;
    .items {
      width: calc(100% - 50px);
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        &.push-content {
          align-items: flex-start;
          img {
            margin-top: 9px;
          }
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }

        .small {
          font-size: px2rem(16);
          line-height: 20px;
          color: #FFFFFF80;
          margin-left: 4px;
          
        }
      }
    }
    .icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 10px;
      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
}

.tags {
  overflow: hidden;
  .wrap-tags {
    width: 100%;
    height: 35px;
    display: flex;
    span {
      background: #ffffff33;
      font-size: px2rem(20);
      padding: 0 12px;
      margin-right: 8px;
      border-radius: 18px;
      white-space: nowrap;
    }
  }
}

.video-title {
  width: 100%;
  display: flex;
  span {
    font-size: px2rem(20);
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.push-content { 
  span {
    font-size: px2rem(20);
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.map {
  width: 420px;
  height: 100%;
  background: #4A5C7880;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 30%;
  }
}
</style>